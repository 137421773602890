import msdyn365Commerce, * as Msdyn365 from '@msdyn365-commerce/core';

export class GetBotFrameworkInput implements Msdyn365.IActionInput {
    public getCacheKey = () => 'CURRENT-BOTFRAMEWORKTOKEN';
    public getCacheObjectType = () => 'CURRENT-BOTFRAMEWORK-KEY1';
    public dataCacheType = (): Msdyn365.CacheType => 'instance';
}

const createInput = (args: Msdyn365.ICreateActionContext<string>): Msdyn365.IActionInput => {
    return new GetBotFrameworkInput();
};

async function action(input: GetBotFrameworkInput, ctx: Msdyn365.IActionContext): Promise<string> {
    const ISecretValue = <any>(
        await msdyn365Commerce.secretManager?.getSecretValue('PVA-WEBCHANNELSECURITY-KEY', ctx.requestContext.apiSettings.baseUrl)
    );
    const apiToken = ISecretValue?.value;
    return apiToken;
}

export default Msdyn365.createObservableDataAction({
    action: <Msdyn365.IAction<string>>action,
    id: 'getBotFrameworkToken',
    input: createInput
});
